<div class="container">
  <form [formGroup]="form" (submit)="onSubmit()">
    <mat-card>
      <mat-card-content>
        <div align="center">
          <div class="icf-logo-icon"></div>
        </div>
        <div *ngIf="!loading">
          <!-- <mat-form-field class="full">
            <input matInput placeholder="Email address" formControlName="username">
          </mat-form-field><br>
          <mat-form-field class="full">
            <input matInput type="password" placeholder="Password" [type]="visiblePassword ? 'text' : 'password'"
              formControlName="password">
            <mat-icon matSuffix (click)="visiblePassword=!visiblePassword">
              {{visiblePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
          <div class="actions-wrapper" align="end">
            <button mat-flat-button type="submit" color="primary">LOGIN</button>
          </div> -->
          <div align="center">
            <button mat-flat-button type="button" color="accent" (click)="googleAuth()">Login with
              iCrimeFighter Account</button>
          </div>
        </div>
        <div *ngIf="loading" align="center">
          <br />
          <mat-spinner></mat-spinner>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  <div class="app-footer">
    <div class="app-version">
      <span>© iCrimeFighter - At-Scene LLC {{ now.getFullYear() }}. All Rights Reserved. MDM Version:
        {{ version }}.</span>
    </div>
  </div>
</div>